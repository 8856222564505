import React from "react"
import { Columns, Section } from "../../components/Containers"
import { Text, Image } from "../../components/Core"

function OurMission(props) {
  const { sideColumnIs } = props
  return (
    <Section className="our-mission">
      <Columns sideColumnsSize={sideColumnIs}>
        <div className="column px-40--mobile our-mission__content">
          <div className="our-mission__img-container">
            <Image publicId={"OSID/DEV/osid-our-mission"} />
          </div>
          <Text
            className="our-mission__content-text"
            text={props.post.ourMission}
          />
        </div>
      </Columns>
    </Section>
  )
}

export default OurMission
