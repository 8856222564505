import React from "react"
import classNames from "classnames"
import { Section, Columns } from "../../components/Containers"
import { Text } from "../../components/Core"
import Accordion from "../../components/accordion/Accordion"

function ReasonsWhy({
  heading,
  list,
  colorBack,
  hasThisSection,
  roundedFull,
  roundedTop,
  mobileTranslateUp
}) {
  if (!hasThisSection) return <></>

  const mainClasses = classNames("rw", {
    rounded: roundedFull,
    "rounded-top": roundedTop,
    "translate-up": mobileTranslateUp
  })

  return (
    <Section colorBack={colorBack} className={mainClasses}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="rw__container">
            <Text
              as="h2"
              className="h3-style mt-0 px-40--mobile"
              text={heading}
            />
            <div className="rw__list">
              {list.map((card, i) => (
                <div key={card.heading} className="rw__accordion">
                  <Accordion number={i + 1} heading={card.heading}>
                    <Text
                      useStringReplace
                      as="p"
                      className="rw__card-blurb"
                      text={card.blurb}
                    />
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default ReasonsWhy
